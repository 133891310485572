(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "angularJS"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("angularJS"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.angularJS);
    global.ngSmartdownDirective = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _angularJS) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports["default"] = void 0;
  _angularJS = _interopRequireDefault(_angularJS);

  function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

  /* eslint angular/window-service: 0 */

  /* eslint no-extend-native: 0 */

  /* eslint angular/typecheck-number: 0 */
  var smartdown = window.smartdown;

  function fixupSource(baseName) {
    var path = require(smartdownRsrc + baseName + '.svg');

    path = decodeURIComponent(path);
    path = decodeURIComponent(path); // var prefix = 'data:image/svg+xml;charset=utf8,module.exports = \'\\\'data:image/svg+xml;charset=utf8,';

    var prefix = 'module.exports = \'';
    var suffix = '\\\'\'';

    if (!String.prototype.startsWith) {
      String.prototype.startsWith = function (searchString, position) {
        position = position || 0;
        return this.substr(position, searchString.length) === searchString;
      };
    }

    if (!String.prototype.endsWith) {
      String.prototype.endsWith = function (searchString, position) {
        var subjectString = this.toString();

        if (typeof position !== 'number' || !isFinite(position) || Math.floor(position) !== position || position > subjectString.length) {
          position = subjectString.length;
        }

        position -= searchString.length;
        var lastIndex = subjectString.indexOf(searchString, position);
        return lastIndex !== -1 && lastIndex === position;
      };
    }

    if (path.startsWith(prefix)) {
      path = path.slice(prefix.length);
    }

    if (path.endsWith(suffix)) {
      path = path.slice(0, -suffix.length);
    }

    return path;
  }

  var gameIcons = ['ancient-ruins', 'aqueduct', 'arena', 'arson', 'badge', 'bank', 'barn', 'beehive', 'block-house', 'brick-pile', 'brick-wall', 'bridge', 'capitol', 'castle', 'castle2', 'church', 'closed-doors', 'defensive-wall', 'demolish', 'egyptian-pyramids', 'entry-door', 'exit-door', 'factory', 'fountain', 'gate', 'gold-mine', 'graveyard', 'greek-temple', 'guarded-tower', 'heart-tower', 'house', 'i-beam', 'igloo', 'indian-palace', 'ionic-column', 'ladder', 'lighthouse', 'locked-fortress', 'magic-gate', 'magic-portal', 'mayan-pyramid', 'maze', 'medieval-gate', 'medieval-pavilion', 'mucous-pillar', 'obelisk', 'observatory', 'oil-rig', 'palisade', 'portculis', 'radar-dish', 'saloon-doors', 'shinto-shrine', 'shop', 'siege-tower', 'stairs', 'stone-bridge', 'stone-tower', 'tesla-turret', 'theater-curtains', 'tipi', 'tower-bridge', 'tower-fall', 'traffic-cone', 'viking-longhouse', 'village', 'warp-pipe', 'watchtower', 'water-fountain', 'well', 'wheelbarrow', 'white-tower', 'wind-turbine', 'windmill', 'windpump', 'wooden-door', 'wrecking-ball', 'Hypercube', 'StalactiteStalagmite'];
  var media = {};
  gameIcons.forEach(function (i) {
    // media[i] = fixupSource(i);
    media[i] = "/gallery/resources/".concat(i, ".svg");
  });
  media.hypercube = media.Hypercube; // function loaderAsync(cardKey, done) {
  //   var scope = angular.element(document.getElementById('infoclay')).scope();
  //   scope.infoclay.getCard(cardKey, done);
  // }

  function loaderJump(cardKey, done) {
    var infoclayScope = _angularJS["default"].element(document.getElementById('infoclay')).scope();

    if (infoclayScope && infoclayScope.infoclay) {
      infoclayScope.infoclay.goToTablet(cardKey, function () {
        // smartdown.startAutoplay();
        if (done) {
          done();
        }
      });
    } else {
      var scapeScope = _angularJS["default"].element(document.getElementById('scape')).scope();

      if (scapeScope && scapeScope.scape) {
        scapeScope.scape.goToTablet(cardKey, function () {
          smartdown.startAutoplay();

          if (done) {
            done();
          }
        });
      }
    }
  }

  function calcExpression(calcKey, calcBody, done) {
    var scope = _angularJS["default"].element(document.getElementById('infoclay')).scope();

    scope.infoclay.calcExpression(calcKey, calcBody, done);
  }

  function calcWikidata(key, body, done) {
    function lookupComplete() {
      /* eslint no-invalid-this: 0 */
      var lookupResult = this.responseText;
      var parsedResult = JSON.parse(lookupResult).query.pages;
      var thumbs = [];
      parsedResult.forEach(function (val, idx) {
        if (val.thumbnail) {
          thumbs.push(val.thumbnail.source);
        }
      });
      done(thumbs);
    }

    var wdKey = body;

    if (body.indexOf('[') === 0) {
      var possibleJSONArray = JSON.parse(body);

      if (Array.isArray(possibleJSONArray)) {
        wdKey = '';
        possibleJSONArray.forEach(function (val, idx) {
          wdKey += val;

          if (idx < possibleJSONArray.length - 1) {
            wdKey += '|';
          }
        });
      }
    }

    var oReq = new XMLHttpRequest();
    oReq.addEventListener("load", lookupComplete);
    var url = 'https://en.wikipedia.org/w/api.php?action=query&formatversion=2&prop=pageimages%7Cpageterms&';
    url += 'titles=' + encodeURI(wdKey); // Albert%20Einstein%7CAlbert%20Ellis%7CAlbert%20Estopinal';

    url += '&pilimit=3&piprop=thumbnail&wbptterms=description&redirects=&format=json&origin=*';
    oReq.open('GET', url);
    oReq.send();
  }

  var calcHandlers = Object.assign(smartdown.defaultCalcHandlers, {
    lookup: calcExpression,
    rooms: calcExpression,
    cardsById: calcExpression,
    genrelist: calcExpression
  });
  var smartdownInitialized = false;

  function loadExample() {
    smartdownInitialized = true;
  }

  var baseURL = window.smartdownSite + '/';
  var replace = window.location.pathname + 'rooms?room=';
  var linkRules = [{
    prefix: '/resources/',
    replace: '/gallery/resources/'
  }, {
    prefix: '/scape?room=',
    replace: replace
  }, {
    prefix: 'rooms?room=',
    replace: replace
  }, {
    prefix: '/rooms?room=',
    replace: replace
  }, {
    prefix: '/#/rooms?room=',
    replace: replace
  }, {
    prefix: '#/rooms?room=',
    replace: replace
  }, {
    prefix: '/#/scape?room=',
    replace: replace
  }, {
    prefix: '#/scape?room=',
    replace: replace
  }, {
    prefix: 'scape?room=',
    replace: replace
  }];
  smartdown.initialize(media, baseURL, loadExample, loaderJump, calcHandlers, linkRules);
  /* eslint new-cap: 0 */

  var smartdownLocked = false;

  function commonHandler($element, $compile, $scope, $timeout, text) {
    if (smartdownInitialized) {
      if (!smartdownLocked) {
        // console.log('smartdown.setSmartdown LOCK', text.slice(0, 40));
        smartdownLocked = true; // console.log('NG smartdown.setSmartdown', text.slice(0, 20), $element[0]);

        smartdown.setSmartdown(text, $element[0], function () {
          // smartdown.startAutoplay($element[0]);
          // console.log('emit smartdown-complete', text.slice(0, 20), $element[0]);
          $scope.$emit('smartdown-complete', text, $element[0]);
        });
        smartdownLocked = false; // console.log('smartdown.setSmartdown RELEASE', text.slice(0, 40));
      } else {
        console.log('smartdown.setSmartdown LOCKED', text.slice(0, 40));
      }
    } else {
      $timeout(function () {
        commonHandler($element, $compile, $scope, $timeout, text);
      }, 0);
    }
  }

  function smartdownDirective($timeout) {
    var uniqueId = 1;
    return {
      name: 'smartdown',
      restrict: "AE",
      priority: 15,
      controller: ["$scope", "$element", "$attrs", '$compile', function ($scope, $element, $attrs, $compile) {
        var id = $element.attr('id');

        if (!id || id === '') {
          id = 'smartdown_' + uniqueId++; // console.log('...newid', id);

          $element.attr('id', id);
        } else {// console.log('...oldid', id);
        }

        if ($attrs.smartdown) {
          $scope.$watch($attrs.smartdown, function (value) {
            // var $script = angular.element("<script type='math/tex'>")   // ("<script type='math/tex'>")
            //     .html(value == undefined ? "" : value);
            // $element.html("");
            // $element.append($script);
            var text = value || value === 0 ? value : '';

            if (!_angularJS["default"].isString(text)) {
              // console.log('mathjaxNonString:', text);
              // console.log('mathjaxNonString $element:', $element);
              // console.log('mathjaxNonString $attrs:', $attrs);
              text = '' + text;
            }

            commonHandler($element, $compile, $scope, $timeout, text);
          });
        } else {
          commonHandler($element, $compile, $scope, $timeout, $element.text());
        }
      }]
    };
  }

  smartdownDirective.$inject = ['$timeout'];

  var _default = _angularJS["default"].module('directives.smartdown', []).directive('smartdown', smartdownDirective).name;

  _exports["default"] = _default;
});