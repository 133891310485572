(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["pouchdb"], factory);
  } else if (typeof exports !== "undefined") {
    factory(require("pouchdb"));
  } else {
    var mod = {
      exports: {}
    };
    factory(global.pouchdb);
    global.rservice = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_pouchdb) {
  "use strict";

  _pouchdb = _interopRequireDefault(_pouchdb);

  function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

  var Promise = require('promise');

  _pouchdb["default"].plugin(require('pouchdb-load'));

  _pouchdb["default"].plugin(require('pouchdb-erase')); // PouchDB.debug.disable();  // 'pouchdb:api'


  var path = require('path');

  var batch = require('./batch');

  var jsonGraph = require('falcor-json-graph');

  function RService(browserDB) {
    var that = this;
    var dbName = browserDB ? 'rdb' : path.join(__dirname, 'db/rdb');
    this.recommendationsDB = new _pouchdb["default"](dbName);
    var initializeDB = true;

    function installDB() {
      that.recommendationsDB = new _pouchdb["default"](dbName);
      var imports;

      if (typeof EnvWebPack === 'undefined') {
        var fs = require('fs');

        imports = fs.readFileSync('dbTemplates/rdb.txt') + '';
      } else {
        imports = require("raw-loader!../../../dbTemplates/rdb.txt");
      }

      var opts = {
        ajax: {
          withCredentials: false
        }
      };
      that.recommendationsDB.load(imports, opts).then(function () {// console.log('RService() DB imported');
      })["catch"](function (err) {
        console.log('RService() DB import error:', err, imports);
      });
    }

    if (initializeDB) {
      that.recommendationsDB.destroy().then(function (response) {
        installDB();
      })["catch"](function (err) {
        console.log('RService() DB does not exist:', err);
        installDB();
      });
    }
  }

  RService.prototype = {
    getGenreList: function getGenreList(userId) {
      var that = this;
      userId = (userId || 'all').toString();
      var getGenreLists = batch(function (userIds) {
        return that.recommendationsDB.allDocs({
          keys: userIds.map(function (x) {
            return x.toString();
          }),
          include_docs: true
        }).then(function (dbResponse) {
          var genreLists = {};
          dbResponse.rows.forEach(function (row) {
            genreLists[row.key] = row;
          });
          return genreLists;
        });
      });
      var result = getGenreLists([userId]).then(function (genreLists) {
        return genreLists[userId].doc.recommendations;
      });
      return result;
    },
    addTitleToGenreList: function addTitleToGenreList(userId, genreIndex, titleId) {
      var that = this;
      userId = userId.toString();
      return that.recommendationsDB.get(userId).then(function (response) {
        if (!response.recommendations[genreIndex]) {
          return Promise.reject(new Error("genrelist does not exist at index " + genreIndex));
        }

        var titlesLength = response.recommendations[genreIndex].titles.push(titleId);
        return that.recommendationsDB.put({
          _id: userId,
          _rev: response._rev,
          recommendations: response.recommendations
        }).then(function () {
          return titlesLength;
        });
      });
    },
    removeTitleFromGenreListByIndex: function removeTitleFromGenreListByIndex(userId, genreIndex, titleIndex) {
      var that = this;
      userId = userId.toString();
      return that.recommendationsDB.get(userId).then(function (response) {
        if (!response.recommendations[genreIndex]) {
          return Promise.reject(new Error("genrelist does not exist at index " + genreIndex));
        }

        var removedTitleId = response.recommendations[genreIndex].titles.splice(titleIndex, 1)[0];
        return that.recommendationsDB.put({
          _id: userId,
          _rev: response._rev,
          recommendations: response.recommendations
        }).then(function () {
          return {
            titleId: removedTitleId,
            length: response.recommendations[genreIndex].titles.length
          };
        });
      });
    }
  };
  module.exports = RService;
});