(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "angularJS"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("angularJS"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.angularJS);
    global.scrollDirective = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _angularJS) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports["default"] = void 0;
  _angularJS = _interopRequireDefault(_angularJS);

  function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

  function scroll($timeout) {
    return {
      restrict: 'A',
      link: function link(scope, element, attr) {
        scope.$watchCollection(attr.scroll, function (newVal) {
          $timeout(function () {
            element[0].scrollTop = element[0].scrollHeight;
          });
        });
      }
    };
  }

  scroll.$inject = ['$timeout'];

  var _default = _angularJS["default"].module('directives.scroll', []).directive('scroll', scroll).name;

  _exports["default"] = _default;
});