(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define([], factory);
  } else if (typeof exports !== "undefined") {
    factory();
  } else {
    var mod = {
      exports: {}
    };
    factory();
    global.galleryIndex = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function () {
  "use strict";

  function partitionMultipart(tablet) {
    var markdown = tablet.text;
    markdown = '\n' + markdown; // deal with lack of leading \n

    var splits = markdown.split(/\n# ([a-zA-Z0-9_]+)\n\-\-\-\n/);
    var result = [];
    var firstKey = null;

    for (var i = 1; i < splits.length; i += 2) {
      result.push({
        _id: splits[i],
        author: tablet.author,
        title: tablet.title + splits[i],
        text: splits[i + 1]
      });

      if (!firstKey) {
        firstKey = splits[i];
      }
    }

    var defaultKeyName = 'Home';

    if (!firstKey) {
      result.push({
        _id: 'Home',
        author: tablet.author,
        title: tablet.title + 'Home',
        text: markdown
      });
    }

    return result;
  }

  var tabletNames = [// 'AAADebug',
  'Badges', 'Brython', 'Cells', 'Conic', 'Cuneiform', 'D3', 'Data', 'Decorations', 'Disclosables', 'DisclosablesPlus', 'Dungeon', 'Extensions', 'Fonts', 'Games', 'GIFAndAudio', 'Gists', // 'GoDown',
  'Graphviz', 'Home', 'Images', 'Inlines', 'Javascript', 'JSPsych', 'LDF', 'MadLibs', 'Maps', 'Mandelbrot', 'Markdown', 'Math', 'Mermaid', 'Mobius', 'Multicards', 'Music', 'OpenJSCAD', 'P5JS', 'Plotly', 'Processes', 'SVG', 'Stdlib', 'Temperature', 'Three', 'Tree', 'Tweets', 'VectorField', 'VectorTree', 'Video', 'VideoAPI', 'YouTube'];
  var projectMap = {};
  var sdREADMEProjectTablets = [{
    _id: 'Welcome',
    author: 'Bud',
    title: 'Smartdown README Project',
    text: require('raw-loader!smartdown/README.md')
  }];
  projectMap["Smartdown README"] = {
    name: 'README',
    data: [],
    tablets: sdREADMEProjectTablets
  };
  var galleryHelloWorldProjectDataCards = [{
    _id: 'TheFirstCard',
    author: 'Bud',
    title: 'The First Card',
    text: "\n- This is a **data card**.\n- Data cards are currently underutilized in the InfoClay Demo.\n- Data cards are queryable via the Falcor API.\n- Data cards are for data, and are not intended for presentation,\nalthough currently any textual content is rendered via Smartdown\n"
  }];
  var galleryHelloWorldProjectTablets = [{
    _id: 'Welcome',
    author: 'Bud',
    title: 'Hello World Project',
    text: require('raw-loader!../../../gallery/HelloWorldProject.md')
  }];
  var galleryTourProjectTablets = [{
    _id: 'Welcome',
    author: 'Bud',
    title: 'InfoClay Tour Project',
    text: require('raw-loader!../../../gallery/TourProject.md')
  }];
  projectMap['InfoClay Hello World'] = {
    data: galleryHelloWorldProjectDataCards,
    tablets: partitionMultipart(galleryHelloWorldProjectTablets[0])
  };
  projectMap['InfoClay Tour'] = {
    data: [],
    tablets: partitionMultipart(galleryTourProjectTablets[0])
  }; // var tabletSets = [];

  tabletNames.forEach(function (name) {
    var tabletSet = [{
      _id: name,
      author: 'DoctorBud',
      title: "Smartdown ".concat(name, " Project"),
      text: require("raw-loader!smartdownGallery/".concat(name, ".md"))
    }]; // tabletSets.push(tabletSet);

    var project = {
      name: name,
      data: [],
      tablets: partitionMultipart(tabletSet[0])
    };
    projectMap["".concat(name)] = project;
  });
  module.exports = projectMap;
});