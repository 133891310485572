(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["pouchdb"], factory);
  } else if (typeof exports !== "undefined") {
    factory(require("pouchdb"));
  } else {
    var mod = {
      exports: {}
    };
    factory(global.pouchdb);
    global.tservice = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_pouchdb) {
  "use strict";

  _pouchdb = _interopRequireDefault(_pouchdb);

  function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

  _pouchdb["default"].plugin(require('pouchdb-load'));

  _pouchdb["default"].plugin(require('pouchdb-erase')); // PouchDB.debug.disable();  // 'pouchdb:api'


  var batch = require('./batch');

  var path = require('path');

  function TService(browserDB) {
    var that = this;
    var dbName = browserDB ? 'tdb' : path.join(__dirname, 'db/tdb');
    this.titlesDB = new _pouchdb["default"](dbName);
    var initializeDB = true;

    function installDB() {
      that.titlesDB = new _pouchdb["default"](dbName);
      var imports;

      if (typeof EnvWebPack === 'undefined') {
        var fs = require('fs');

        imports = fs.readFileSync('dbTemplates/tdb.txt') + '';
      } else {
        imports = require("raw-loader!../../../dbTemplates/tdb.txt");
      }

      var opts = {
        ajax: {
          withCredentials: false
        }
      };
      that.titlesDB.load(imports, opts).then(function () {// console.log('TService() DB imported');
      })["catch"](function (err) {
        console.log('TService() DB import error:', err);
      });
    }

    if (initializeDB) {
      that.titlesDB.destroy().then(function (response) {
        installDB();
      })["catch"](function (err) {
        console.log('TService() DB destroy error:', err); // installDB();
      });
    }
  }

  TService.prototype = {
    getTitles: function getTitles(titleIds) {
      var that = this;
      var getTitlesBatch = batch(function (titleIds2) {
        return that.titlesDB.allDocs({
          keys: titleIds2.map(function (x) {
            return x.toString();
          }),
          include_docs: true
        }).then(function (dbResponse) {
          var titles = {};
          dbResponse.rows.forEach(function (row) {
            if (row.error) {
              if (row.error === "not_found") {
                titles[row.key] = {
                  doc: null
                };
              } else {
                titles[row.key] = {
                  error: row.error
                };
              }
            } else if (row.doc) {
              titles[row.key] = row;
            } else {
              titles[row.key] = {
                doc: null
              };
            }
          });
          return titles;
        });
      });
      return getTitlesBatch(titleIds).then(function (titles) {
        return titles;
      });
    }
  };
  module.exports = TService;
});