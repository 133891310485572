(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "./stack.less", "../infoclay/infoclay.less", "angularJS", "@uirouter/angularjs", "./stack.routes", "./stack.controller", "../../themes/default/background.png"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("./stack.less"), require("../infoclay/infoclay.less"), require("angularJS"), require("@uirouter/angularjs"), require("./stack.routes"), require("./stack.controller"), require("../../themes/default/background.png"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.stack, global.infoclay, global.angularJS, global.angularjs, global.stack, global.stack, global.background);
    global.index = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _stack, _infoclay, _angularJS, _angularjs, _stack2, _stack3, _background) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports["default"] = void 0;
  _angularJS = _interopRequireDefault(_angularJS);
  _angularjs = _interopRequireDefault(_angularjs);
  _stack2 = _interopRequireDefault(_stack2);
  _stack3 = _interopRequireDefault(_stack3);

  function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

  var _default = _angularJS["default"].module('app.stack', [_angularjs["default"]]).config(_stack2["default"]).controller('StackController', _stack3["default"]).name;

  _exports["default"] = _default;
});