(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define([], factory);
  } else if (typeof exports !== "undefined") {
    factory();
  } else {
    var mod = {
      exports: {}
    };
    factory();
    global.index = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function () {
  'use strict';
  /* eslint no-undefined: 0 */

  var Router = require('falcor-router');

  var Promise = require('promise');

  var _ = require('underscore');

  var jsonGraph = require('falcor-json-graph');

  var $ref = jsonGraph.ref;
  var $error = jsonGraph.error; // from: http://stackoverflow.com/questions/17575790/environment-detection-node-js-or-browser

  var isNode = new Function("try {return this===global;}catch(e){return false;}");
  /* eslint no-new-func: 0 */

  var browserDB = !isNode();

  var ChatService = require('./chatservice');

  var MyRouterBase = Router.createClass([{
    route: "infoclay.syncTo",
    set: function set(jsonGraphArg) {
      console.log('infoclay.syncTo', jsonGraphArg);
      var syncResult = this.chatService.syncTo(jsonGraphArg.infoclay.syncTo);
      return {
        path: ['infoclay', 'syncTo'],
        value: jsonGraphArg.infoclay.syncTo
      };
    }
  }, {
    route: "infoclay.syncFrom",
    set: function set(jsonGraphArg) {
      console.log('infoclay.syncFrom', jsonGraphArg);
      var syncResult = this.chatService.syncFrom(jsonGraphArg.infoclay.syncFrom);
      return {
        path: ['infoclay', 'syncFrom'],
        value: jsonGraphArg.infoclay.syncFrom
      };
    }
  }, {
    route: "cardsById[{keys:cardIds}]['author', 'title', 'text', 'cardId']",
    get: function get(pathSet) {
      return this.chatService.getCards(pathSet.cardIds).then(function (cards) {
        var results = [];
        pathSet.cardIds.forEach(function (cardId) {
          pathSet[2].forEach(function (key) {
            var textRecord = cards[cardId];

            if (textRecord.error) {
              results.push({
                path: ['cardsById', cardId, key],
                value: $error(textRecord.error)
              });
            } else if (textRecord.doc) {
              var mappedDoc = textRecord.doc[key];

              if (key === 'cardId') {
                mappedDoc = textRecord.doc._id;
              }

              results.push({
                path: ['cardsById', cardId, key],
                value: mappedDoc
              });
            } else {
              results.push({
                path: ['cardsById', cardId],
                value: undefined
              });
            }
          });
        });
        return results;
      });
    }
  }, {
    route: "cardsById[{keys:cardIds}].text",
    set: function set(jsonGraphArg) {
      var cardIdsAndText = jsonGraphArg.cardsById;
      var ids = Object.keys(cardIdsAndText);
      return this.chatService.setCardsText(cardIdsAndText).then(function (cards) {
        return ids.map(function (id) {
          if (cards[id].error) {
            return {
              path: ['cardsById', id],
              value: $error(cards[id].error)
            };
          } else if (cards[id].doc) {
            return {
              path: ['cardsById', id, 'text'],
              value: cards[id].doc.text
            };
          } else {
            return {
              path: ['cardsById', id],
              value: undefined
            };
          }
        });
      });
    }
  }, {
    route: "cardsById[{keys:cardIds}].title",
    set: function set(jsonGraphArg) {
      var cardIdsAndTitle = jsonGraphArg.cardsById;
      var ids = Object.keys(cardIdsAndTitle);
      return this.chatService.setCardsTitle(cardIdsAndTitle).then(function (cards) {
        return ids.map(function (id) {
          if (cards[id].error) {
            return {
              path: ['cardsById', id],
              value: $error(cards[id].error)
            };
          } else if (cards[id].doc) {
            return {
              path: ['cardsById', id, 'title'],
              value: cards[id].doc.title
            };
          } else {
            return {
              path: ['cardsById', id],
              value: undefined
            };
          }
        });
      });
    }
  }, {
    route: "rooms[{integers:indices}].title",
    get: function get(pathSet) {
      return this.chatService.getRooms().then(function (rooms) {
        // use the indices alias to retrieve the array (equivalent to pathSet[1])
        return pathSet.indices.map(function (index) {
          var list = rooms[index];

          if (list === null) {
            return {
              path: ["rooms", index],
              value: list
            };
          }

          return {
            path: ['rooms', index, 'title'],
            value: rooms[index].title
          };
        });
      });
    }
  }, {
    route: "rooms[{integers:indices}].messages[{integers:messageIndices}]",
    get: function get(pathSet) {
      return this.chatService.getRooms().then(function (rooms) {
        var pathValues = [];
        pathSet.indices.forEach(function (index) {
          var room = rooms[index]; // If we determine that there is no room at the index, we must
          // be specific and return that it is the room that is not
          // present and not the name of the room.

          if (room === null) {
            pathValues.push({
              path: ['rooms', index],
              value: room
            });
          } else {
            pathSet.messageIndices.forEach(function (messageIndex) {
              var message = room.messages[messageIndex];

              if (message === null) {
                pathValues.push({
                  path: ["rooms", index, "messages", messageIndex],
                  value: message
                });
              } else {
                pathValues.push({
                  path: ['rooms', index, 'messages', messageIndex],
                  value: message
                });
              }
            });
          }
        });
        return pathValues;
      });
    }
  }, {
    route: "rooms[{integers:indices}].cards[{integers:cardIndices}]",
    get: function get(pathSet) {
      return this.chatService.getRooms().then(function (rooms) {
        var pathValues = [];
        pathSet.indices.forEach(function (index) {
          var room = rooms[index]; // If we determine that there is no room at the index, we must
          // be specific and return that it is the room that is not
          // present and not the name of the room.

          if (room === null) {
            pathValues.push({
              path: ['rooms', index],
              value: room
            });
          } else {
            pathSet.cardIndices.forEach(function (cardIndex) {
              var cardId = room.cards[cardIndex].cardId;

              if (cardId === null) {
                pathValues.push({
                  path: ["rooms", index, "cards", cardIndex],
                  value: cardId
                });
              } else {
                pathValues.push({
                  path: ['rooms', index, 'cards', cardIndex],
                  value: $ref(['cardsById', cardId])
                });
              }
            });
          }
        });
        return pathValues;
      });
    }
  }, {
    route: 'rooms[{integers:indices}].cards.length',
    get: function get(pathSet) {
      return this.chatService.getRooms().then(function (rooms) {
        var result = pathSet.indices.map(function (index) {
          var list = rooms[index]; // If we determine that there is no genre at the index, we must
          // be specific and return that it is the genre that is not
          // present and not the name of the genre.

          if (list === null) {
            return {
              path: ["rooms", index],
              value: list
            };
          }

          return {
            path: ['rooms', index, 'cards', 'length'],
            value: list.cards.length
          };
        });
        return result;
      });
    }
  }, {
    route: 'rooms.length',
    get: function get(pathSet) {
      return this.chatService.getRooms().then(function (rooms) {
        return {
          path: ['rooms', 'length'],
          value: rooms.length
        };
      });
    }
  }, {
    route: 'rooms[{integers:indices}].messages.length',
    get: function get(pathSet) {
      return this.chatService.getRooms().then(function (rooms) {
        return pathSet.indices.map(function (index) {
          var list = rooms[index]; // If we determine that there is no genre at the index, we must
          // be specific and return that it is the genre that is not
          // present and not the name of the genre.

          if (list === null) {
            return {
              path: ["rooms", index],
              value: list
            };
          }

          return {
            path: ['rooms', index, 'messages', 'length'],
            value: list.messages.length
          };
        });
      });
    }
  }, {
    route: 'rooms[{integers:indices}].messages.remove',
    call: function call(callPath, args) {
      var roomIndex = callPath.indices[0];
      var messageIndex = args[0];
      return this.chatService.removeMessageFromRoomByIndex(roomIndex, messageIndex).then(function (messageIdAndLength) {
        return [{
          path: ['rooms', roomIndex, 'messages', {
            from: messageIndex,
            to: messageIdAndLength.length
          }],
          invalidated: true
        }, {
          path: ['rooms', roomIndex, 'messages', 'length'],
          value: messageIdAndLength.length
        }];
      });
    }
  }, {
    route: "rooms[{integers:indices}].messages[{integers:mindices}].text",
    set: function set(jsonGraphArg) {
      var roomIdsAndMessages = jsonGraphArg.rooms;
      var ids = Object.keys(roomIdsAndMessages);
      var resultPaths = [];
      var emulate = false;

      if (emulate) {
        _.each(ids, function (id) {
          var room = roomIdsAndMessages[id];
          var messageIds = room.messages;

          _.each(messageIds, function (mid, midkey) {
            var messageIndex = midkey;
            var message = room.messages[messageIndex];
            message.text = mid.text;
            resultPaths.push({
              path: ['rooms', id, 'messages', messageIndex, 'text'],
              value: mid.text
            });
          });
        });
      } else {
        var roomIndex = ids[0];
        var arg0 = roomIdsAndMessages[roomIndex];
        var messages0 = arg0.messages;
        var messages0Index = Object.keys(messages0)[0];
        var messages0Text = messages0[messages0Index].text;
        resultPaths = this.chatService.updateMessageTextInRoomByIndex(roomIndex, messages0Index, messages0Text).then(function (length) {
          var resultx = [{
            path: ['rooms', roomIndex, 'messages', messages0Index, 'text'],
            value: messages0Text
          }];
          return resultx;
        });
      }

      return resultPaths;
    }
  }, // {
  //     route: 'rooms[{integers:indices}].messages.push',
  //     set: function(callPath, args) {
  //         // validating that argument to add to the list is a reference to an item in the messagesById map:
  //         var message = args[0];
  //         var roomIndex = callPath.indices[0];
  //         var result = this.chatService.
  //             addMessageToRoomByIndex(roomIndex, message).
  //             then(function(length) {
  //               var resultx = [
  //                             {
  //                                 path: ['rooms', roomIndex, 'messages', length - 1],
  //                                 value: { $type: "atom", value: message }
  //                             },
  //                             {
  //                                 path: ['rooms', roomIndex, 'messages', 'length'],
  //                                 value: length
  //                             }
  //                         ];
  //               console.log('messages.push:', resultx);
  //               return resultx;
  //             });
  //         return result;
  //     }
  // },
  {
    route: 'rooms[{integers:indices}].messages.push',
    call: function call(callPath, args) {
      // validating that argument to add to the list is a reference to an item in the messagesById map:
      var message = args[0];
      var roomIndex = callPath.indices[0];
      var result = this.chatService.addMessageToRoomByIndex(roomIndex, message).then(function (length) {
        var resultx = [{
          path: ['rooms', roomIndex, 'messages', length - 1],
          value: {
            $type: "atom",
            value: message
          }
        }, {
          path: ['rooms', roomIndex, 'messages', 'length'],
          value: length
        }];
        console.log('messages.push:', resultx);
        return resultx;
      });
      return result;
    }
  }, {
    route: 'rooms[{integers:indices}].cards.remove',
    call: function call(callPath, args) {
      var roomIndex = callPath.indices[0];
      var cardIndex = args[0];
      return this.chatService.removeCardFromRoomByIndex(roomIndex, cardIndex).then(function (messageIdAndLength) {
        return [{
          path: ['rooms', roomIndex, 'cards', {
            from: cardIndex,
            to: messageIdAndLength.length
          }],
          invalidated: true
        }, {
          path: ['rooms', roomIndex, 'cards', 'length'],
          value: messageIdAndLength.length
        }];
      });
    }
  },
  /*
      Not currently used, and therefore not tested...
      This route enables an existing card to be added to a room's cards[] list.
      Note that this function does not create the card, just a reference within the room.
      It is assumed (but unchecked) that the cardRef points to an existing card.
  
      {
          route: 'rooms[{integers:indices}].cards.push',
          call: function(callPath, args) {
              console.log('rooms[{integers:indices}].cards.push');
  
              // validating that argument to add to the list is a reference to an item in the titlesById map:
              var cardRef = args[0];
              console.log('rooms[{integers:indices}].cards.push cardRef:', cardRef);
              if (cardRef === null || cardRef.$type !== "ref" || cardRef.value[0] !== "cardsById" || cardRef.value.length !== 2) {
                  throw new Error("invalid input");
              }
  
              // retrieving the card id from the reference path:
              var cardId = cardRef.value[1];
              console.log('rooms[{integers:indices}].cards.push cardId:', cardId);
  
              var roomIndex = callPath.indices[0];
  
              console.log('rooms[{integers:indices}].cards.push roomIndex:', roomIndex, ' cardId:', cardId);
  
              var result = chatService.
                  addCardToRoomById(roomIndex, cardId).
                  then(function(length) {
                    console.log('rooms[{integers:indices}].cards.push length:', length);
  
                    var resultx = [
                        {
                            path: ['rooms', roomIndex, 'cards', length - 1],
                            value: cardRef
                        },
                        {
                            path: ['rooms', roomIndex, 'cards', 'length'],
                            value: length
                        }
                    ];
  
                    return resultx;
                  });
              return result;
          }
      },
  */
  {
    route: 'rooms[{integers:indices}].cards.pushVal',
    call: function call(callPath, args) {
      // validating that argument to add to the list is a reference to an item in the titlesById map:
      var cardVal = args[0];
      var roomIndex = callPath.indices[0];
      var result = this.chatService.addCardToRoom(roomIndex, cardVal).then(function (length) {
        var cardRef = {
          $type: "ref",
          value: ['cardsById', cardVal._id]
        };
        var resultx = [{
          path: ['rooms', roomIndex, 'cards', length - 1],
          value: cardRef
        }, {
          path: ['rooms', roomIndex, 'cards', 'length'],
          value: length
        }];
        return resultx;
      });
      return result;
    }
  }]);

  function MyRouter(userId, resetDB, preloadProject, initializedCb) {
    MyRouterBase.call(this);
    this.userId = userId;
    this.chatService = new ChatService(browserDB, resetDB, preloadProject, initializedCb);
  }

  MyRouter.prototype = Object.create(MyRouterBase.prototype);

  module.exports = function (userId, resetDB, preloadProject, initializedCb) {
    return new MyRouter(userId, resetDB, preloadProject, initializedCb);
  };
});