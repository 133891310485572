(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "./about.less", "angularJS", "@uirouter/angularjs", "./about.routes", "./about.controller", "./aboutModal.controller"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("./about.less"), require("angularJS"), require("@uirouter/angularjs"), require("./about.routes"), require("./about.controller"), require("./aboutModal.controller"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.about, global.angularJS, global.angularjs, global.about, global.about, global.aboutModal);
    global.index = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _about, _angularJS, _angularjs, _about2, _about3, _aboutModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports["default"] = void 0;
  _angularJS = _interopRequireDefault(_angularJS);
  _angularjs = _interopRequireDefault(_angularjs);
  _about2 = _interopRequireDefault(_about2);
  _about3 = _interopRequireDefault(_about3);
  _aboutModal = _interopRequireDefault(_aboutModal);

  function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

  var _default = _angularJS["default"].module('app.about', [_angularjs["default"]]).config(_about2["default"]).controller('AboutController', _about3["default"]).controller('AboutModalController', _aboutModal["default"]).name;

  _exports["default"] = _default;
});