(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define([], factory);
  } else if (typeof exports !== "undefined") {
    factory();
  } else {
    var mod = {
      exports: {}
    };
    factory();
    global.infoclayToolbox = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function () {
  "use strict";

  /* eslint angular/log: 0 */

  /* eslint angular/window-service: 0 */

  /* eslint angular/document-service: 0 */
  var toolbox = [{
    type: "container",
    template: true,
    subtype: "text",
    id: 0,
    md: "Section",
    columns: [[{
      type: "item",
      template: true,
      subtype: "text",
      id: 0,
      md: "Your Content Here"
    }]]
  }, // {type: "container", title: "2-Column", columns: [[], []]},
  // {type: "container", title: "3-Column", columns: [[], [], []]},
  // {type: "item", template: true, subtype: "text", id: 0, md: "debug template instance"},
  {
    type: "item",
    template: true,
    subtype: "text",
    id: 0,
    md: require('raw-loader!./toolbox/markdown.md')
  }, {
    type: "item",
    template: true,
    subtype: "text",
    id: 0,
    md: require('raw-loader!./toolbox/styles.md')
  }, {
    type: "item",
    template: true,
    subtype: "math",
    id: 0,
    md: require('raw-loader!./toolbox/math.md')
  }, {
    type: "item",
    template: true,
    subtype: "math",
    id: 0,
    md: require('raw-loader!./toolbox/code.md')
  }, {
    type: "item",
    template: true,
    subtype: "math",
    id: 0,
    md: require('raw-loader!./toolbox/table.md')
  }, {
    type: "item",
    template: true,
    subtype: "graphic",
    id: 0,
    md: require('raw-loader!./toolbox/graphic.md')
  }, {
    type: "item",
    template: true,
    subtype: "graphic",
    id: 0,
    md: require('raw-loader!./toolbox/svg.md')
  }, {
    type: "item",
    template: true,
    subtype: "graphic",
    id: 0,
    md: require('raw-loader!./toolbox/tweets.md')
  }, {
    type: "item",
    template: true,
    subtype: "text",
    id: 0,
    md: "[Input](:?TEMPLATECELLID)"
  }, {
    type: "item",
    template: true,
    subtype: "text",
    id: 0,
    md: "[Output](:!TEMPLATECELLID)"
  }, {
    type: "item",
    template: true,
    subtype: "text",
    id: 0,
    md: "[Query](:=TEMPLATECELLID=/genrelist[0].titles.length)"
  }, {
    type: "item",
    template: true,
    subtype: "graphic",
    id: 0,
    md: require('raw-loader!./toolbox/p5js.md')
  }, {
    type: "item",
    template: true,
    subtype: "graphic",
    id: 0,
    md: require('raw-loader!./toolbox/poem.md')
  }];
  module.exports = toolbox;
});