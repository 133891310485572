(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "angularJS", "@uirouter/angularjs", "./infoclay.routes", "./infoclay.controller", "../../themes/default/background.png", "../../services/netflix.service", "../../services/infoclay.service"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("angularJS"), require("@uirouter/angularjs"), require("./infoclay.routes"), require("./infoclay.controller"), require("../../themes/default/background.png"), require("../../services/netflix.service"), require("../../services/infoclay.service"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.angularJS, global.angularjs, global.infoclay, global.infoclay, global.background, global.netflix, global.infoclay);
    global.index = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _angularJS, _angularjs, _infoclay, _infoclay2, _background, _netflix, _infoclay3) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports["default"] = void 0;
  _angularJS = _interopRequireDefault(_angularJS);
  _angularjs = _interopRequireDefault(_angularjs);
  _infoclay = _interopRequireDefault(_infoclay);
  _infoclay2 = _interopRequireDefault(_infoclay2);
  _netflix = _interopRequireDefault(_netflix);
  _infoclay3 = _interopRequireDefault(_infoclay3);

  function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

  // import './infoclay.less';
  var _default = _angularJS["default"].module('app.infoclay', [_angularjs["default"], _netflix["default"], _infoclay3["default"]]).config(_infoclay["default"]).controller('InfoClayController', _infoclay2["default"]).name;

  _exports["default"] = _default;
});