(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "./settings.less", "angularJS", "@uirouter/angularjs", "./settings.routes", "./settings.controller", "./settingsModal.controller"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("./settings.less"), require("angularJS"), require("@uirouter/angularjs"), require("./settings.routes"), require("./settings.controller"), require("./settingsModal.controller"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.settings, global.angularJS, global.angularjs, global.settings, global.settings, global.settingsModal);
    global.index = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _settings, _angularJS, _angularjs, _settings2, _settings3, _settingsModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports["default"] = void 0;
  _angularJS = _interopRequireDefault(_angularJS);
  _angularjs = _interopRequireDefault(_angularjs);
  _settings2 = _interopRequireDefault(_settings2);
  _settings3 = _interopRequireDefault(_settings3);
  _settingsModal = _interopRequireDefault(_settingsModal);

  function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

  var _default = _angularJS["default"].module('app.settings', [_angularjs["default"]]).config(_settings2["default"]).controller('SettingsController', _settings3["default"]).controller('SettingsModalController', _settingsModal["default"]).name;

  _exports["default"] = _default;
});