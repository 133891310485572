(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "./navbar.controller", "angularJS"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("./navbar.controller"), require("angularJS"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.navbar, global.angularJS);
    global.index = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _navbar, _angularJS) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports["default"] = void 0;
  _navbar = _interopRequireDefault(_navbar);
  _angularJS = _interopRequireDefault(_angularJS);

  function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

  var _default = _angularJS["default"].module('app.navbar', []).controller('NavbarController', _navbar["default"]).name;

  _exports["default"] = _default;
});