function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["./favicon.ico", "pouchdb", "pouchdb-load", "pouchdb-erase", "angular", "@uirouter/angularjs", "angular-sanitize", "angular-animate", "angular-dialog-service", "angular-ui-bootstrap", "angular-fontawesome", "font-awesome/css/font-awesome.min.css", "./app.config", "./features/infoclay", "./themes/default/index.less", "./themes/default/titledlogo.png", "jsonformatter", "jsonformatter/dist/json-formatter.min.css", "./directives/scroll.directive.js", "./directives/fileselect.directive.js", "smartdownJS", "smartdownCalcHandlersJS", "smartdownCSS", "smartdownFontsCSS", "codemirrorJS", "codemirror/lib/codemirror.css", "codemirror/theme/neat.css", "codemirror/addon/edit/continuelist.js", "codemirror/addon/display/fullscreen.js", "codemirror/mode/markdown/markdown.js", "codemirror/addon/mode/overlay.js", "codemirror/addon/display/placeholder.js", "codemirror/addon/selection/mark-selection.js", "codemirror/mode/gfm/gfm.js", "codemirror/mode/xml/xml.js", "./directives/ng-smartdown.directive.js", "./features/stack", "./features/about", "./features/settings", "./features/navbar", "angular-drag-and-drop-lists"], factory);
  } else if (typeof exports !== "undefined") {
    factory(require("./favicon.ico"), require("pouchdb"), require("pouchdb-load"), require("pouchdb-erase"), require("angular"), require("@uirouter/angularjs"), require("angular-sanitize"), require("angular-animate"), require("angular-dialog-service"), require("angular-ui-bootstrap"), require("angular-fontawesome"), require("font-awesome/css/font-awesome.min.css"), require("./app.config"), require("./features/infoclay"), require("./themes/default/index.less"), require("./themes/default/titledlogo.png"), require("jsonformatter"), require("jsonformatter/dist/json-formatter.min.css"), require("./directives/scroll.directive.js"), require("./directives/fileselect.directive.js"), require("smartdownJS"), require("smartdownCalcHandlersJS"), require("smartdownCSS"), require("smartdownFontsCSS"), require("codemirrorJS"), require("codemirror/lib/codemirror.css"), require("codemirror/theme/neat.css"), require("codemirror/addon/edit/continuelist.js"), require("codemirror/addon/display/fullscreen.js"), require("codemirror/mode/markdown/markdown.js"), require("codemirror/addon/mode/overlay.js"), require("codemirror/addon/display/placeholder.js"), require("codemirror/addon/selection/mark-selection.js"), require("codemirror/mode/gfm/gfm.js"), require("codemirror/mode/xml/xml.js"), require("./directives/ng-smartdown.directive.js"), require("./features/stack"), require("./features/about"), require("./features/settings"), require("./features/navbar"), require("angular-drag-and-drop-lists"));
  } else {
    var mod = {
      exports: {}
    };
    factory(global.favicon, global.pouchdb, global.pouchdbLoad, global.pouchdbErase, global.angular, global.angularjs, global.angularSanitize, global.angularAnimate, global.angularDialogService, global.angularUiBootstrap, global.angularFontawesome, global.fontAwesomeMin, global.app, global.infoclay, global.index, global.titledlogo, global.jsonformatter, global.jsonFormatterMin, global.scrollDirective, global.fileselectDirective, global.smartdownJS, global.smartdownCalcHandlersJS, global.smartdownCSS, global.smartdownFontsCSS, global.codemirrorJS, global.codemirror, global.neat, global.continuelist, global.fullscreen, global.markdown, global.overlay, global.placeholder, global.markSelection, global.gfm, global.xml, global.ngSmartdownDirective, global.stack, global.about, global.settings, global.navbar, global.angularDragAndDropLists);
    global.app = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_favicon, PouchDB, PouchLoad, PouchErase, _angular, _angularjs, _angularSanitize, _angularAnimate, _angularDialogService, _angularUiBootstrap, _angularFontawesome, _fontAwesomeMin, _app, _infoclay, _index, _titledlogo, _jsonformatter, _jsonFormatterMin, _scrollDirective, _fileselectDirective, _smartdownJS, _smartdownCalcHandlersJS, _smartdownCSS, _smartdownFontsCSS, _codemirrorJS, _codemirror, _neat, _continuelist, _fullscreen, _markdown, _overlay, _placeholder, _markSelection, _gfm, _xml, _ngSmartdownDirective, _stack, _about, _settings, _navbar, _angularDragAndDropLists) {
  "use strict";

  PouchDB = _interopRequireWildcard(PouchDB);
  PouchLoad = _interopRequireWildcard(PouchLoad);
  PouchErase = _interopRequireWildcard(PouchErase);
  _angular = _interopRequireDefault(_angular);
  _angularjs = _interopRequireDefault(_angularjs);
  _angularSanitize = _interopRequireDefault(_angularSanitize);
  _angularAnimate = _interopRequireDefault(_angularAnimate);
  _angularDialogService = _interopRequireDefault(_angularDialogService);
  _angularUiBootstrap = _interopRequireDefault(_angularUiBootstrap);
  _app = _interopRequireDefault(_app);
  _infoclay = _interopRequireDefault(_infoclay);
  _jsonformatter = _interopRequireDefault(_jsonformatter);
  _jsonFormatterMin = _interopRequireDefault(_jsonFormatterMin);
  _scrollDirective = _interopRequireDefault(_scrollDirective);
  _fileselectDirective = _interopRequireDefault(_fileselectDirective);
  _smartdownJS = _interopRequireDefault(_smartdownJS);
  _codemirrorJS = _interopRequireDefault(_codemirrorJS);
  _ngSmartdownDirective = _interopRequireDefault(_ngSmartdownDirective);
  _stack = _interopRequireDefault(_stack);
  _about = _interopRequireDefault(_about);
  _settings = _interopRequireDefault(_settings);
  _navbar = _interopRequireDefault(_navbar);
  _angularDragAndDropLists = _interopRequireDefault(_angularDragAndDropLists);

  function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

  function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

  function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

  /* xeslint-disable */
  // require('offline-plugin/runtime').install();
  PouchDB["default"].plugin(PouchLoad);
  PouchDB["default"].plugin(PouchErase);
  window.CodeMirror = _codemirrorJS["default"];
  window.marked = _smartdownJS["default"].marked;
  global.marked = _smartdownJS["default"].marked;
  window.smartdown = _smartdownJS["default"];
  global.smartdown = _smartdownJS["default"];

  (function () {
    var app = _angular["default"].module('app', [_angularjs["default"], _angularSanitize["default"], _angularUiBootstrap["default"],
    /* ngdragdrop, */
    _angularAnimate["default"], 'dialogs.main', 'dndLists',
    /* 'hc.marked', */
    'picardy.fontawesome',
    /* d3feature, */
    // , twitter, dnd,
    _jsonformatter["default"], _scrollDirective["default"], _ngSmartdownDirective["default"], _fileselectDirective["default"],
    /* falcor, */
    _stack["default"], _infoclay["default"], _about["default"], _settings["default"], _navbar["default"], 'ui.codemirror'], ['$rootScopeProvider', function ($rootScopeProvider) {
      $rootScopeProvider.digestTtl(15);
    }]);

    app.config(_app["default"]);
    app.config(['JSONFormatterConfigProvider', function (JSONFormatterConfigProvider) {
      JSONFormatterConfigProvider.hoverPreviewEnabled = true;
    }]); // https://github.com/angular-ui/ui-codemirror

    function uiCodemirrorDirective($timeout, uiCodemirrorConfig) {
      function newCodemirrorEditor(iElement, codemirrorOptions) {
        var codemirrot;

        if (iElement[0].tagName === 'TEXTAREA') {
          // Might bug but still ...
          codemirrot = window.CodeMirror.fromTextArea(iElement[0], codemirrorOptions);
        } else {
          iElement.html('');
          codemirrot = new window.CodeMirror(function (cmElement) {
            iElement.append(cmElement);
          }, codemirrorOptions);
        }

        return codemirrot;
      }

      function configOptionsWatcher(codemirrot, uiCodemirrorAttr, scope) {
        if (!uiCodemirrorAttr) {
          return;
        }

        var codemirrorDefaultsKeys = Object.keys(window.CodeMirror.defaults);

        function updateOptions(newValues, oldValue) {
          if (!_angular["default"].isObject(newValues)) {
            return;
          }

          codemirrorDefaultsKeys.forEach(function (key) {
            if (newValues.hasOwnProperty(key)) {
              if (oldValue && newValues[key] === oldValue[key]) {
                return;
              }

              codemirrot.setOption(key, newValues[key]);
            }
          });
        }

        scope.$watch(uiCodemirrorAttr, updateOptions, true);
      }

      function configNgModelLink(codemirror, ngModel, scope) {
        if (!ngModel) {
          return;
        } // CodeMirror expects a string, so make sure it gets one.
        // This does not change the model.


        ngModel.$formatters.push(function (value) {
          if (_angular["default"].isUndefined(value) || value === null) {
            return '';
          } else if (_angular["default"].isObject(value) || _angular["default"].isArray(value)) {
            throw new Error('ui-codemirror cannot use an object or an array as a model');
          }

          return value;
        }); // Override the ngModelController $render method, which is what gets called when the model is updated.
        // This takes care of the synchronizing the codeMirror element with the underlying model, in the case that it is changed by something else.

        ngModel.$render = function () {
          // Code mirror expects a string so make sure it gets one
          // Although the formatter have already done this, it can be possible that another formatter returns undefined (for example the required directive)
          var safeViewValue = ngModel.$viewValue || '';
          codemirror.setValue(safeViewValue);
        }; // Keep the ngModel in sync with changes from CodeMirror


        codemirror.on('change', function (instance) {
          var newValue = instance.getValue();

          if (newValue !== ngModel.$viewValue) {
            scope.$evalAsync(function () {
              ngModel.$setViewValue(newValue);
            });
          }
        });
      }

      function configUiRefreshAttribute(codeMirror, uiRefreshAttr, scope) {
        if (!uiRefreshAttr) {
          return;
        }

        scope.$watch(uiRefreshAttr, function (newVal, oldVal) {
          // Skip the initial watch firing
          if (newVal !== oldVal) {
            $timeout(function () {
              codeMirror.refresh();
            });
          }
        });
      }

      function postLink(scope, iElement, iAttrs, ngModel) {
        var codemirrorOptions = _angular["default"].extend({
          value: iElement.text()
        }, uiCodemirrorConfig.codemirror || {}, scope.$eval(iAttrs.uiCodemirror), scope.$eval(iAttrs.uiCodemirrorOpts));

        var codemirror = newCodemirrorEditor(iElement, codemirrorOptions);
        configOptionsWatcher(codemirror, iAttrs.uiCodemirror || iAttrs.uiCodemirrorOpts, scope);
        configNgModelLink(codemirror, ngModel, scope);
        configUiRefreshAttribute(codemirror, iAttrs.uiRefresh, scope); // Allow access to the CodeMirror instance through a broadcasted event
        // eg: $broadcast('CodeMirror', function(cm){...});

        scope.$on('CodeMirror', function (event, callback) {
          if (_angular["default"].isFunction(callback)) {
            return callback(codemirror);
          } else {
            throw new Error('the CodeMirror event requires a callback function');
          }
        }); // onLoad callback

        if (_angular["default"].isFunction(codemirrorOptions.onLoad)) {
          codemirrorOptions.onLoad(codemirror);
        }
      }

      return {
        restrict: 'EA',
        require: '?ngModel',
        compile: function compile() {
          // Require CodeMirror
          if (_angular["default"].isUndefined(window.CodeMirror)) {
            throw new Error('ui-codemirror needs CodeMirror to work... (o rly?)');
          }

          return postLink;
        }
      };
    }

    uiCodemirrorDirective.$inject = ['$timeout', 'uiCodemirrorConfig'];
    /**
     * Binds a CodeMirror widget to a <textarea> element.
     */

    _angular["default"].module('ui.codemirror', []).constant('uiCodemirrorConfig', {}).directive('uiCodemirror', uiCodemirrorDirective);

    app.run(['$timeout', '$state', '$location', '$rootScope', function ($timeout, $state, $location, $rootScope) {
      // $rootScope.$on('$stateChangeStart',
      //     function(event, toState, toParams, fromState, fromParams, options) {
      //       console.log('stateChangeStart:', window.p5);
      //     });
      $rootScope.showNavbar = function () {
        return $location.path() !== '/tabletpreview' && $location.path() !== '/tabletplay';
      }; // console.log('$location.path:', $location.path());
      // if ($location.path() === '/') {
      //   $timeout(
      //     function () {
      //       console.log('go infoclay');
      //       $state.go('infoclay');
      //     },
      //     10);
      // }

    }]);
  })();
});