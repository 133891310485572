(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["pouchdb", "./galleryIndex.js"], factory);
  } else if (typeof exports !== "undefined") {
    factory(require("pouchdb"), require("./galleryIndex.js"));
  } else {
    var mod = {
      exports: {}
    };
    factory(global.pouchdb, global.galleryIndex);
    global.chatservice = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_pouchdb, _galleryIndex) {
  "use strict";

  _pouchdb = _interopRequireDefault(_pouchdb);
  _galleryIndex = _interopRequireDefault(_galleryIndex);

  function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

  /* eslint angular/log: 0 */
  var cloudantUser = 'andialfalmorredstreciene';
  var cloudantPw = 'dbfd6592351461681a57faf3a010ab2ae800c510';

  var _ = require('underscore');

  var Promise = require('promise');

  _pouchdb["default"].plugin(require('pouchdb-load'));

  _pouchdb["default"].plugin(require('pouchdb-erase')); // PouchDB.debug.disable();  // 'pouchdb:api'


  var path = require('path');

  var manifest = {
    _id: 'InfoClayManifest',
    version: 16
  };
  var roomIndexToId = ['DataStack', 'ProjectStack'];

  var batch = require('./batch');

  // chat service
  function ChatService(browserDB, resetDB, preloadProject, initializedUserCb) {
    var that = this;
    that.projectIndex = _galleryIndex["default"];
    var dbName = browserDB ? 'cdb' : path.join(__dirname, 'db/cdb');

    function loadProject(p, completionCb) {
      console.log('chatservice.loadProject:', p);
      that.chatDB.get('ProjectStack').then(function (doc) {
        console.log('ChatService.loadProject() found ProjectStack:', doc);
        var oldProjectStack = doc.cards;
        var oldProperties = doc.messages;
        doc.messages = p.properties;
        console.log('ChatService.loadProject() p:', p);
        console.log('ChatService.loadProject() oldProjectStack:', oldProjectStack);
        var cardIdsToBeDeleted = oldProjectStack.map(function (c) {
          return c.cardId;
        });
        console.log('cardIdsToBeDeleted', cardIdsToBeDeleted); // Gotta delete the old cards since we are currently using
        // hardcoded IDs.

        that.chatDB.allDocs({
          keys: cardIdsToBeDeleted,
          include_docs: false
        }).then(function (cardIdsToBeDeletedResponse) {
          console.log('cardIdsToBeDeletedResponse', cardIdsToBeDeletedResponse);
          var deleteProjectStack = cardIdsToBeDeletedResponse.rows.map(function (c) {
            return {
              _id: c.id,
              _rev: c.value.rev,
              _deleted: true
            };
          });
          console.log('deleteProjectStack', deleteProjectStack);
          that.chatDB.bulkDocs(deleteProjectStack).then(function (deleteProjectStackResponse) {
            console.log('ChatService.loadProject() deleteProjectStackResponse:', deleteProjectStackResponse); // Create the new tablets now

            var newProjectStack = _.map(p.tablets, function (c) {
              c._id = c.cardId;
              return c;
            });

            console.log('ChatService.loadProject() newProjectStack:', newProjectStack);
            that.chatDB.bulkDocs(newProjectStack).then(function (newProjectStackResponse) {
              console.log('ChatService.loadProject() newProjectStack created:', newProjectStackResponse);
              doc.cards = newProjectStackResponse.map(function (c) {
                return {
                  cardId: c.id
                };
              });
              console.log('ChatService.loadProject() put doc:', doc);
              that.chatDB.put(doc).then(function (response) {
                console.log('ChatService.loadProject() updated ProjectStack:', response);
                completionCb();
              })["catch"](function (createErr) {
                console.log('ChatService.loadProject() unable to update ProjectStack:', createErr);
                completionCb();
              });
            })["catch"](function (newProjectStackError) {
              console.log('ChatService.loadProject() newProjectStackError:', newProjectStackError);
              completionCb();
            });
          })["catch"](function (newProjectStackError) {
            console.log('ChatService.loadProject() newProjectStackError:', newProjectStackError);
            completionCb();
          });
        });
      })["catch"](function (err) {
        console.log('ChatService.createProjectStack() NOTFOUND ProjectStack:', err);
        completionCb();
      });
      completionCb();
    }

    function initializedCb() {
      if (preloadProject) {
        loadProject(preloadProject, initializedUserCb);
      } else {
        initializedUserCb();
      }
    }

    function installDB(resetDBName) {
      var chatDB = that.chatDB;
      var project = _galleryIndex["default"][resetDBName] || _galleryIndex["default"]['InfoClay Hello World'];

      function createDataStack() {
        var cards = project.data;
        var card1Doc = chatDB.bulkDocs(cards).then(function (response) {
          chatDB.get('DataStack').then(function (doc) {
            console.log('ChatService.createDataStack() found DataStack:', doc);
          })["catch"](function (err) {
            var data = {
              _id: 'DataStack',
              title: 'Data Stack',
              cards: [],
              messages: [{
                author: 'RoomType',
                text: 'Chat'
              }, {
                author: 'Description',
                text: 'This is an example of a Chat Room built with InfoClay'
              }, {
                author: 'SomeOtherMetadata',
                text: 'Anything can go here. Eventually any data type, including media!'
              }, {
                author: 'GrueProbability',
                text: '50%'
              }]
            };
            data.cards = _.map(cards, function (card) {
              return {
                cardId: card._id
              };
            });
            var newDoc = chatDB.put(data).then(function (innerResponse) {
              console.log('ChatService.createDataStack() created DataStack:', innerResponse);
            })["catch"](function (createErr) {
              console.log('ChatService.createDataStack() unable to create DataStack:', createErr);
            });
          });
        })["catch"](function (createErr) {
          console.log('ChatService.createDataStack() unable to create:', createErr);
        });
      }

      function createProjectStack() {
        var tablets = project.tablets;
        var card1Doc = chatDB.bulkDocs(tablets).then(function (response) {
          chatDB.get('ProjectStack').then(function (doc) {
            console.log('ChatService.createProjectStack() found ProjectStack:', doc);
          })["catch"](function (err) {
            var data = {
              _id: 'ProjectStack',
              title: 'Notebook Stack',
              cards: [],
              messages: [{
                author: 'RoomType',
                text: 'Project'
              }, {
                author: 'Description',
                text: 'This example Project contains Tablets which can be edited in the Workshop, or Previewed or Played. Eventually, we will have a Publish capability to export to common platforms like GitHubPages, Wordpress, etc.'
              }]
            };
            data.cards = _.map(tablets, function (card) {
              return {
                cardId: card._id
              };
            });
            var newDoc = chatDB.put(data).then(function (innerResponse) {
              console.log('ChatService.createProjectStack() created:', innerResponse);
              initializedCb();
            })["catch"](function (createErr) {
              console.log('ChatService.createProjectStack() unable to create:', createErr);
            });
          });
        })["catch"](function (createErr) {
          console.log('ChatService.createProjectStack() unable to create:', createErr);
        });
      }

      var manifestDoc = that.chatDB.put(manifest).then(function (manifestResponse) {
        createDataStack();
        createProjectStack();
      })["catch"](function (createErr) {
        console.log('###manifest writing ERR:', createErr);
      });
    }

    var initializeDB = false || resetDB;
    this.chatDBName = dbName;
    this.chatDB = new _pouchdb["default"](dbName); // , {size: 50});

    this.chatDB.get('InfoClayManifest').then(function (result) {
      var manifestVersion = result.version;

      if (manifestVersion < manifest.version) {
        console.log('Manifest out of date. initializeDB');
        initializeDB = true;
      }

      if (initializeDB) {
        // console.log('Initializing DB resetDB', resetDB);
        that.chatDB.destroy().then(function (response) {
          that.chatDB = new _pouchdb["default"](dbName); // , {size: 50});

          installDB(resetDB);
        })["catch"](function (err) {
          console.log('ChatService() DB unable to destroy:', err);
        });
      } else {
        initializedCb();
      }
    })["catch"](function (err) {
      console.log('chatDB manifest NOT found:', err);
      installDB();
    });
  }

  ChatService.prototype = {
    getCards: function getCards(whichCardIds) {
      return this.chatDB.allDocs({
        keys: whichCardIds.map(function (x) {
          return x.toString();
        }),
        include_docs: true
      }).then(function (dbResponse) {
        var cards = {};
        dbResponse.rows.forEach(function (row) {
          if (row.error) {
            if (row.error === "not_found") {
              cards[row.key] = {
                doc: null
              };
            } else {
              cards[row.key] = {
                error: row.error
              };
            }
          } else if (row.doc) {
            cards[row.key] = row;
          } else {
            cards[row.key] = {
              doc: null
            };
          }
        });
        return cards;
      });
    },
    setCardsText: function setCardsText(cardIdsAndText) {
      var that = this;
      var ids = Object.keys(cardIdsAndText);
      return this.chatDB.allDocs({
        keys: ids.map(function (id) {
          return id;
        }),
        include_docs: true
      }).then(function (getResponse) {
        var filteredIds = ids.filter(function (id, index) {
          return !(getResponse.rows[index].doc === null || getResponse.rows[index].error === "not_found");
        });
        var docs = filteredIds.map(function (id, index) {
          return {
            _id: id,
            _rev: !getResponse.rows[index].error ? getResponse.rows[index].value.rev : null,
            text: cardIdsAndText[id].text,
            title: getResponse.rows[index].doc.title,
            author: getResponse.rows[index].doc.author
          }; // var mutated = Object.assign({}, getResponse.rows[index]);
          // mutated._rev = (!getResponse.rows[index].error ? getResponse.rows[index].value.rev : null);
          // mutated.doc.text = cardIdsAndText[id].text;
          // console.log('mutated:', mutated);
          // return mutated;
        });
        return that.chatDB.bulkDocs(docs).then(function (setResponse) {
          var results = {};
          getResponse.rows.forEach(function (response, index) {
            if (!setResponse[index]) {
              results[response.key] = {
                doc: null
              };
            } else if (setResponse[index].ok) {
              if (getResponse.rows[index].doc === null || getResponse.rows[index].error) {
                results[response.key] = {
                  id: setResponse[index].id,
                  key: setResponse[index].id,
                  value: {
                    rev: setResponse[index].rev
                  },
                  doc: {
                    text: cardIdsAndText[response.key].text,
                    _id: setResponse[index].id,
                    _rev: setResponse[index].rev
                  }
                };
              } else {
                response.doc.text = cardIdsAndText[response.key].text;
                results[response.key] = response;
              }
            } else {
              results[response.key] = {
                error: setResponse[index].message
              };
            }
          });
          return results;
        });
      });
    },
    setCardsTitle: function setCardsTitle(cardIdsAndTitle) {
      var that = this;
      console.log('#setCardsTitle:', cardIdsAndTitle);
      var ids = Object.keys(cardIdsAndTitle);
      return this.chatDB.allDocs({
        keys: ids.map(function (id) {
          return id;
        }),
        include_docs: true
      }).then(function (getResponse) {
        return that.chatDB.bulkDocs(ids.filter(function (id, index) {
          return !(getResponse.rows[index].doc === null || getResponse.rows[index].error === "not_found");
        }).map(function (id, index) {
          return {
            _id: id,
            _rev: !getResponse.rows[index].error ? getResponse.rows[index].value.rev : null,
            title: cardIdsAndTitle[id].title
          };
        })).then(function (setResponse) {
          var results = {};
          getResponse.rows.forEach(function (response, index) {
            if (!setResponse[index]) {
              results[response.key] = {
                doc: null
              };
            } else if (setResponse[index].ok) {
              if (getResponse.rows[index].doc === null || getResponse.rows[index].error) {
                results[response.key] = {
                  id: setResponse[index].id,
                  key: setResponse[index].id,
                  value: {
                    rev: setResponse[index].rev
                  },
                  doc: {
                    title: cardIdsAndTitle[response.key].title,
                    _id: setResponse[index].id,
                    _rev: setResponse[index].rev
                  }
                };
              } else {
                response.doc.title = cardIdsAndTitle[response.key].title;
                results[response.key] = response;
              }
            } else {
              results[response.key] = {
                error: setResponse[index].message
              };
            }
          });
          return results;
        });
      });
    },
    getRoom: function getRoom(roomId) {
      var that = this;
      var getRoomsBatch = batch(function (roomIds) {
        return that.chatDB.allDocs({
          keys: roomIds.map(function (x) {
            return x.toString();
          }),
          include_docs: true
        }).then(function (dbResponse) {
          var roomMessages = {};
          dbResponse.rows.forEach(function (row) {
            roomMessages[row.key] = row;
          });
          return roomMessages;
        });
      });
      return getRoomsBatch([roomId]).then(function (rooms) {
        var room = rooms[roomId].doc;
        return [room];
      });
    },
    getRooms: function getRooms() {
      return this.chatDB.allDocs({
        keys: roomIndexToId.map(function (x) {
          return x.toString();
        }),
        include_docs: true
      }).then(function (dbResponse) {
        var rooms = [];
        dbResponse.rows.forEach(function (row) {
          rooms.push(row.doc);
        });
        return rooms;
      });
    },
    addMessageToRoomByIndex: function addMessageToRoomByIndex(roomIndex, message) {
      var that = this;
      var roomId = roomIndexToId[roomIndex];
      var result = that.chatDB.get(roomId).then(function (response) {
        var messages = response.messages;
        var messagesLength = messages.push(message);
        response.messages = messages;
        return that.chatDB.put(response).then(function (putResult) {
          return messagesLength;
        })["catch"](function (createErr) {
          console.log('ChatService() error in chatDB.put:', createErr);
        });
      })["catch"](function (createErr) {
        console.log('ChatService() error in chatDB.get:', roomId, createErr);
      });
      return result;
    },
    updateMessageTextInRoomByIndex: function updateMessageTextInRoomByIndex(roomIndex, messageIndex, messageText) {
      var that = this;
      var roomId = roomIndexToId[roomIndex];
      var result = that.chatDB.get(roomId).then(function (response) {
        var messages = response.messages;
        var oldMessage = messages[messageIndex];
        var messagesLength = messages.length;
        messages[messageIndex].text = messageText;
        response.messages = messages;
        return that.chatDB.put(response).then(function (putResult) {
          return messagesLength;
        })["catch"](function (createErr) {
          console.log('ChatService() error in chatDB.put:', createErr);
        });
      })["catch"](function (createErr) {
        console.log('ChatService() error in chatDB.get:', roomId, createErr);
      });
      return result;
    },
    removeMessageFromRoomByIndex: function removeMessageFromRoomByIndex(roomIndex, messageIndex) {
      var that = this;
      var roomId = roomIndexToId[roomIndex];
      var result = that.chatDB.get(roomId).then(function (response) {
        var messages = response.messages;
        messages.splice(messageIndex, 1);
        response.messages = messages;
        return that.chatDB.put(response).then(function (putResult) {
          return {
            cardIndex: messageIndex,
            length: messages.length
          };
        })["catch"](function (createErr) {
          console.log('ChatService() error in chatDB.put:', createErr);
        });
      })["catch"](function (createErr) {
        console.log('ChatService() error in chatDB.get:', roomId, createErr);
      });
      return result;
    },

    /*
      Not currently used, and therefore not tested...
      This route enables an existing card to be added to a room's cards[] list.
      Note that this function does not create the card, just a reference within the room.
      It is assumed (but unchecked) that the cardRef points to an existing card.
    
      addCardToRoomById: function(roomIndex, cardId) {
        var roomId = roomIndexToId[roomIndex];
        var result = chatDB.get(roomId)
          .then(function(roomResponse) {
            var room = roomResponse.room;
    
            BAD CODE, or at least BAD SEMANTICS
            - We shouldn't be creating a card, the cardId should be referring to an already
            existing card. The newCard and the .put(newCard) are bad.
            - Maybe we should verify that the cardId points to a valid card, and throw an error
            if it fails.
    
            var newCard = {
                              _id: cardId,
                              author: 'Bud',
                              title: 'Card Id ' + cardId,
                              text: 'The ' + cardId + ' Text'
                          };
    
            var card1Doc = chatDB.put(newCard)
              .then(
                function (cardResponse) {
    
                  var cardRef = { cardId: cardId };
                  var cardsLength = roomResponse.cards.push(cardRef);
                  console.log('...about to update room. cardsLength:', cardsLength);
                  return chatDB.put(roomResponse).then(function(roomResponse2) {
    
                    var resultCheck = chatDB.get(roomId)
                      .then(function(roomResponseCheck) {
                        console.log('Check chatDB.get:', roomResponseCheck);
                      });
    
                    return cardsLength;
                  });
                })
              .catch(
                function (createErr) {
                  console.log('ChatService.addCardToRoomById() unable to create new card:', createErr);
                });
    
            return card1Doc;
          });
    
        console.log('addCardToRoomById returning: ', result);
        return result;
      },
    */
    addCardToRoom: function addCardToRoom(roomIndex, card) {
      var that = this;
      var roomId = roomIndexToId[roomIndex];
      var result = this.chatDB.get(roomId).then(function (roomResponse) {
        var room = roomResponse.room;
        var newCard = card;
        var card1Doc = that.chatDB.put(newCard).then(function (cardResponse) {
          var cardRef = {
            cardId: card._id
          };
          var cardsLength = roomResponse.cards.push(cardRef);
          return that.chatDB.put(roomResponse).then(function (roomResponse2) {
            var resultCheck = that.chatDB.get(roomId).then(function (roomResponseCheck) {// console.log('Check chatDB.get:', roomResponseCheck);
            });
            return cardsLength;
          });
        })["catch"](function (createErr) {
          console.log('ChatService.addCardToRoom() unable to create new card:', createErr);
        });
        return card1Doc;
      });
      return result;
    },
    removeCardFromRoomByIndex: function removeCardFromRoomByIndex(roomIndex, cardIndex) {
      var that = this;
      var roomId = roomIndexToId[roomIndex];
      var result = that.chatDB.get(roomId).then(function (roomResponse) {
        var room = roomResponse;
        var cardList = room.cards;
        cardList.splice(cardIndex, 1);
        room.cards = cardList;
        return that.chatDB.put(room).then(function (x) {
          return {
            cardIndex: cardIndex,
            length: room.cards.length
          };
        }); // console.log('removeCardFromRoomByIndex returning: ', result);
      });
      return result;
    },
    syncTo: function syncTo(remotePath) {
      var that = this;
      console.log('chatservice.syncTo:', remotePath);
      var remoteDBUrl = 'https://74b841e3-639b-4ba2-b8b5-8b79a1e4779c-bluemix.cloudant.com/infoclaygallery';
      var remoteDB2 = new _pouchdb["default"](remoteDBUrl, {
        auth: {
          username: cloudantUser,
          password: cloudantPw
        }
      });
      console.log('chatservice remoteDB2', remoteDB2);
      remoteDB2.allDocs({
        include_docs: false
      }).then(function (docsToDelete) {
        console.log('docsToDelete', docsToDelete);
        var docToDeleteRequest = docsToDelete.rows.map(function (c) {
          return {
            _id: c.id,
            _rev: c.value.rev,
            _deleted: true
          };
        });
        console.log('####docToDeleteRequest', docToDeleteRequest);
        remoteDB2.bulkDocs(docToDeleteRequest).then(function (docToDeleteResponse) {
          console.log('####docToDeleteResponse:', docToDeleteResponse);
          var idsToRevs = {};

          _.each(docToDeleteResponse, function (d) {
            idsToRevs[d.id] = d.rev;
          });

          that.chatDB.allDocs({
            include_docs: true
          }).then(function (docsToAdd) {
            console.log('idsToRevs', idsToRevs);
            console.log('docsToAdd', docsToAdd);

            var newDocs = _.map(docsToAdd.rows, function (a) {
              var result = a.doc;

              if (false && idsToRevs[a._id]) {
                result._rev = idsToRevs[result._id];
              } else {
                delete result._rev;
              }

              return result;
            });

            console.log('####newDocs', newDocs);
            remoteDB2.bulkDocs(newDocs).then(function (newDocsResponse) {
              console.log('####newDocsResponse:', newDocsResponse);
            })["catch"](function (docToDeleteRequestError) {
              console.log('docToDeleteRequestError:', docToDeleteRequestError);
            });
          })["catch"](function (docToDeleteError) {
            console.log('docToDeleteError:', docToDeleteError);
          });
        })["catch"](function (docToDeleteRequestError) {
          console.log('docToDeleteRequestError:', docToDeleteRequestError);
        });
      })["catch"](function (docToDeleteError) {
        console.log('docToDeleteError:', docToDeleteError);
      }); // remoteDB2.erase() // .destroy();
      //   .then(function(response) {
      //     console.log('remoteDB2.erase()', response);
      //     // remoteDB2 = new PouchDB(remoteDBUrl,
      //     //               {
      //     //                 auth: {
      //     //                   username: cloudantUser,
      //     //                   password: cloudantPw
      //     //                 }
      //     //               });
      //     that.chatDB.replicate.to(remoteDB2)
      //       .catch(function (err) {
      //         console.log('chatservice.replicate.to', err);
      //       });
      //   }).catch(function (err) {
      //     console.log(err);
      //   });
    },
    syncFrom: function syncFrom(remotePath) {
      var that = this;
      console.log('chatservice.syncFrom:', remotePath);
      var result = true;
      this.chatDB.destroy().then(function (response) {
        that.chatDB = new _pouchdb["default"](that.chatDBName); // , {size: 50});

        var remoteDBUrl = 'https://doctorbud.cloudant.com/infoclaygallery';
        var remoteDB2 = new _pouchdb["default"](remoteDBUrl, {
          auth: {
            username: cloudantUser,
            password: cloudantPw
          }
        });
        console.log('chatservice remoteDB2', remoteDB2);
        that.chatDB.replicate.from(remoteDB2).then(function (res) {
          console.log('chatservice.replicate.from success', res);
        })["catch"](function (err) {
          console.log('chatservice.replicate.from error', err);
        });
      })["catch"](function (err) {
        console.log(err);
      });
    }
  };
  module.exports = ChatService;
});