(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports);
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports);
    global.infoclayRoutes = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports["default"] = routes;

  var infoclayTpl = require('./infoclay.html');

  function routes($stateProvider) {
    $stateProvider.state('infoclay', {
      url: '/tablet',
      templateUrl: infoclayTpl,
      controller: 'InfoClayController',
      controllerAs: 'infoclay'
    });
  }

  routes.$inject = ['$stateProvider'];
});