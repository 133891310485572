(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "falcor-http-datasource", "angularJS"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("falcor-http-datasource"), require("angularJS"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.falcorHttpDatasource, global.angularJS);
    global.netflixService = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _falcorHttpDatasource, _angularJS) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports["default"] = void 0;
  _falcorHttpDatasource = _interopRequireDefault(_falcorHttpDatasource);
  _angularJS = _interopRequireDefault(_angularJS);

  function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

  /* eslint angular/no-service-method: 0 */
  var useLocalFalcorDB = true;

  var _ = require('underscore');

  var falcor = require('falcor');

  if (useLocalFalcorDB) {
    var falcorRouterFactory = require('./falcor');
  }

  var Netflix = function Netflix($q) {
    _classCallCheck(this, Netflix);

    var service = {};
    /* eslint func-style: 0 */

    var model;

    if (useLocalFalcorDB) {
      var falcorRouterInstance = falcorRouterFactory('1');
      model = new falcor.Model({
        source: falcorRouterInstance
      });
    } else {
      // create model:
      model = new falcor.Model({
        source: new _falcorHttpDatasource["default"]('/model.json')
      });
    }

    service.model = model;
    return service;
  };

  Netflix.$inject = ['$q'];

  var _default = _angularJS["default"].module('services.netflix', []).service('netflix', Netflix).name;

  _exports["default"] = _default;
});