(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define([], factory);
  } else if (typeof exports !== "undefined") {
    factory();
  } else {
    var mod = {
      exports: {}
    };
    factory();
    global.infoclayTemplates = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function () {
  "use strict";

  /* eslint angular/log: 0 */

  /* eslint angular/window-service: 0 */

  /* eslint angular/document-service: 0 */
  var templates = [];
  templates.push([{
    "title": 'Gallery Showcase',
    "type": "item",
    "id": 0,
    "columns": [],
    "md": require('raw-loader!../../../gallery/Showcase.md')
  }]);
  templates.push([{
    "title": 'Gallery Cells',
    "type": "item",
    "id": 1,
    "columns": [],
    "md": require('raw-loader!../../../gallery/Cells.md')
  }]);
  module.exports = templates;
});